.card-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.card-body {
  padding: 20px;
}

.btn-block {
  width: 100%;
}

.btn-block {
  width: 100%;
}

.btn-secondary {
  color: white;
  background-color: #050564;
  border-color: grey;
}

.btn-secondary:hover {
  color: #ffffff;
  background-color: #1df0d4;
  border-color: #050564;
}

.Quiz {
  width: 1000px;
  height: 600px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 20px;
  border-radius: 10px;
}

.QuizName {
  font-size: large;
}

.Menu {
  width: 500px;
  height: 500px;
  border-radius: 10px;
  background-color: #050564;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

button:hover {
  cursor: pointer;
}

#nextQuestion {
  width: 200px;
}

h3 {
  color: #ffffff;
}

.EndScreenContainer {
  margin-top: 20px;
}
.EndScreen h1 {
  color: white;
}

button {
  margin: 5px;
}

.MenuContainer {
  margin-top: 20px;
}

.btn-option {
  width: 100px;
}
