.card-register {
  background-color: white;
  border-radius: 8px;
  color: black;
  max-width: 400px;
  margin-top: 45px;
  margin-bottom: 0px;
  min-height: 400px;
  padding: 30px;

  & label {
    margin-top: 15px;
  }
  & .card-title {
    color: #b33c12;
    text-align: center;
  }
  & .btn {
    margin-top: 30px;
  }
  & .forgot {
    text-align: center;
  }
}
