/* Alumni.css */
.card-blog {
  max-width: 93%;
}
.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
  background: #1a1919;
}

.iframe-container iframe {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

/* About us - Team*/
.team-card-row {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 8px;
  background-color: white;
  margin: 35px 0;
  width: 100%;
}

.team-description {
  color: #666666;
  font-weight: 400;
  margin-bottom: 40px;
  text-align: left;
}

.team-title {
  color: #999999;
  font-size: 14px;
  line-height: 1.1;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: left;
}

.team-name {
  font-family: Poppins;
  color: #000000;
  font-size: 36px;
  font-weight: 900;
  line-height: 1.1;
  text-transform: capitalize;
  visibility: visible;
  align-self: center;
  padding-top: 20px;
}
.team-member-image {
  width: 100%;
  max-height: 650px;
  border-radius: 20px;
}

.brand-image {
  height: 55px;
  width: 95px;
}

body {
  line-height: 1.6;
  font-size: 16px;
  font-weight: 400;
  color: #404040;
}

.title {
  color: #000000;
  font-size: 36px;
  font-weight: 900;
}

/*FAQ Section */
.Carousel {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  height: 300x;
  width: 600px;
}

.carouselItem {
  /* Example styles */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 600px;
  height: 300px;
  text-align: center;
  text-align: center; /* Center the content */
  /* Add more styles as needed */
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 50%;
  /* transform: translateY(-50%);*/
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #dadada;
  cursor: pointer;
  z-index: 1;
  /*filter: invert(100%); /* Change the color to white/black */
}
.carousel-control-prev {
  left: 10px;
}
.carousel-control-next {
  right: 10px;
}

.carousel-indicators li {
  background-color: #050564;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  cursor: pointer; /* Change the indicator dots color */
}
.carousel-indicators .active {
  background-color: #ffffff;
}
@media (max-width: 768px) {
  .custom-carousel {
    padding: 10px;
  }
  .carousel-control-prev,
  .carousel-control-next {
    width: 20px;
    height: 20px;
  }
}
.card-testimonial {
  background-color: #ffffff;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  height: 100%;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: none !important;
  animation: none !important;
}

.card-avatar {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  background-color: aqua;
  text-align: center;
}

.card-avatar img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 15px;
}

.card-avatar h6 {
  text-align: center;
  align-items: center;
  font-weight: normal;
  color: #0a0a0a;
}

.testimonial-content {
  color: #666;
  text-align: center;
}

/* div with testimonial card */
.testimonials-page {
  margin-top: 0px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 600px;
  background-color: #666363;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding-bottom: 20px;
}

/* src/components/TermsAndConditions.css */
.terms-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.terms-banner {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
}

.terms-content {
  max-width: 800px;
  text-align: left;
  line-height: 1.6;
  background-color: #f0f8ff;
  padding: 50px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.terms-content h1,
.terms-content h2 {
  margin-top: 20px;
}

.terms-content p {
  margin: 10px 0;
}

.terms-content ul {
  margin: 10px 0;
  padding-left: 20px;
}

.terms-content a {
  color: #007bff;
  text-decoration: none;
}

.terms-content a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .motto {
    display: none;
  }

  .bannerCardsContainer {
    margin-top: 0px !important;
  }

  .bannerCard {
    margin-top: 30px;
  }

  .bannerCardTitle {
    text-align: center;
  }

  .countryFlags {
    margin-bottom: 30px;
  }
}
.create-quiz-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  margin-top: 80px;
}

.create-quiz-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.quiz-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.form-group label {
  margin-bottom: 5px;
}

.form-control {
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.form-control:hover {
  border-color: purple;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
}

.btn {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-primary {
  background-color: #113b51;
  color: white;
}

.btn-secondary {
  background-color: #6c757d;
  color: white;
}

.btn:hover {
  opacity: 0.9;
}

.create-quiz-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #007bff;
  font-weight: bold;
}

/* surveylist styling */
.survey-list-container {
  padding: 20px;
}

.search-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.survey-card-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.survey-card {
  flex: 1 1 calc(33.333% - 20px);
  margin: 10px;
  max-width: calc(33.333% - 20px);
}

.card-body-custom {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.button-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
}

.edit-button {
  padding: 3px 8px;
  font-size: 0.8rem;
  width: 150px;
}

.create-survey-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.create-survey-link {
  text-align: center;
}

.no-surveys {
  text-align: center;
  font-size: 1.2rem;
  color: #555;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.create-survey-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.form-group {
  margin-bottom: 1.5rem;
}

.question-group {
  border: 1px solid #e0e0e0;
  padding: 15px;
  border-radius: 5px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
}

.answer-group {
  display: flex;
  flex-direction: column;
}

.form-buttons {
  margin-top: 20px;
}

.add-answer-button,
.save-question-button,
.edit-question-button {
  margin-top: 10px;
}

.btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-success {
  background-color: #28a745;
  border-color: #28a745;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.scrolling-container {
  display: flex;
  overflow: hidden;
  width: 100%;
  position: relative;
}

.scrolling-content {
  display: flex;
  animation: scroll 20s linear infinite;
  position: absolute;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.countryFlags {
  flex: 0 0 auto;
  margin: 10 10px;
}

/* TeamDisplay.css */
.team-card {
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: background-color 0.3s, box-shadow 0.3s;
  width: 100%;
  background: rgba(0, 0, 0, 0.15);
}

.active-card {
  background: #113b51;
  color: white;
}
.active-title {
  color: white;
}

.card-content {
  display: flex;
  align-items: center;
  padding: 0;
  background: rgba(0, 0, 0, 0.15);
}

.team-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  padding: 0px;
  display: flex;
  align-items: center;
}

.card-title {
  margin: 0;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: normal;
}

.selected-img {
  width: 370px;
  height: 415px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
}

.description-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}

.description-container h4 {
  margin-top: 0;
  color: #333;
}

.description-container p {
  color: #555;
  line-height: 1.6;
}

.team-display-container {
  display: flex;
  justify-content: space-between;
}

.team-list {
  flex: 1;
  margin-right: 20px;
}

.team-details {
  flex: 2;
}

.list-wrapper {
  max-width: 600px;
  margin: 0 auto;
}

.ol {
  list-style: none;
  padding: 0;
}

.li + .li {
  margin-top: 1rem;
}

.li {
  display: flex;
  align-items: center;
  gap: 1rem;
  background: #113b51;
  padding: 1.5rem;
  border-radius: 1rem;
  width: calc(100% - 2rem);
  color: white;
}

.li::before {
  counter-increment: list-item;
  content: counter(list-item);
  font-size: 1rem;
  font-weight: 500;
  width: 2em;
  height: 2em;
  background: black;
  flex: 0 0 auto;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.li:nth-child(even) {
  flex-direction: row-reverse;
  margin-right: -2rem;
  margin-left: 2rem;
}

ol li:nth-child(odd) {
  background: rgba(248, 172, 27, 0.4);
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: black;
}
ol li:nth-child(even) {
  background: rgba(17, 59, 81, 0.89);
  /* //backdrop-filter: blur(10px);
  //box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  color: white;
}

/* QuizDisplay.css */
.selected-img-container {
  width: 100%;
  height: 400px; /* Adjust this value to set the desired height of the image space */
  border: 2px solid #ccc; /* Adjust color and width as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.selected-img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: cover;
}
/* In your global CSS file */
.values-section img {
  max-height: 600px;
}

@media (max-width: 768px) {
  .values-section img {
    max-height: 400px;
  }
}

@media (max-width: 480px) {
  .values-section img {
    max-height: 300px;
  }
}

.learn-more-btn {
  transition: transform 0.3s ease, box-shadow 0.3s ease,
    background-color 0.3s ease;
  background-color: #113b51;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.learn-more-btn:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  background-color: #113b51;
}
