.sign-up-link {
  color: #5f9e24;
  font-weight: bold;
  cursor: pointer;
}

.register-p {
  margin: auto;
  margin-top: 20px;
  text-align: center;
}

.cardHeader {
  margin-top: "0px";
  text-align: "center";
  font-weight: "bold";
  margin-bottom: "10px";
}
