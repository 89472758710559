/* Quiz.css - Enhanced with fullscreen layout, animations, and banner */
/* Banner styling */
.quiz-banner {
  background-color: #000080;
  width: 100%;
  height: 100px;
  /* Not too tall banner */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  /* Lower than navbar z-index to appear behind it */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Adjust main container to account for banner */
.quiz-main-container {
  display: flex;
  flex: 1;
  margin-top: 136px;
  /* Adjusted to account for navbar (76px) + banner (60px) */
  margin-bottom: 60px;
  /* Adjust based on your footer height */
}

/* Fullscreen layout */
.quiz-fullscreen {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Sidebar styling */
.quiz-sidebar {
  width: 300px;
  background-color: #f5f6fa;
  padding: 30px 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  position: fixed;
  height: calc(100vh - 196px);
  /* Adjust based on navbar + banner + footer */
  overflow-y: auto;
}

.quiz-progress-info {
  padding-top: 20px;
}

.quiz-progress-info h4 {
  color: #000080;
  font-weight: 600;
  margin-bottom: 10px;
}

.progress-container {
  margin: 25px 0;
}

.progress {
  height: 8px;
  border-radius: 4px;
}

.progress-bar {
  background-color: #000080;
}

/* Section indicators */
.section-progress {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.section-indicator {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 6px;
  background-color: #e9ecef;
  position: relative;
  transition: all 0.3s ease;
}

.section-indicator:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 23px;
  height: 15px;
  width: 2px;
  background-color: #e9ecef;
}

.section-indicator:last-child:after {
  display: none;
}

.section-indicator.active {
  background-color: #000080;
  color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 128, 0.2);
  transform: scale(1.05);
}

.section-indicator.completed {
  background-color: #6c757d;
  color: white;
}

.section-number {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  margin-right: 10px;
  font-weight: bold;
}

/* Main content area */
.quiz-content {
  flex: 1;
  margin-left: 300px;
  /* Same as sidebar width */
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quiz-question-card {
  width: 100%;
  max-width: 800px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
  padding: 40px;
  transition: all 0.3s ease;
}

/* Question styling */
.question-container {
  min-height: 350px;
}

.question-title {
  color: #000080;
  font-weight: 600;
  margin-bottom: 30px;
  /*animation: fadeIn 0.5s ease-in-out;*/
}

/* Option cards */
.option-card {
  position: relative;
  padding: 16px 20px;
  border-radius: 8px;
  background-color: #f8f9fa;
  margin-bottom: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  border: 1px solid #e9ecef;
  /*animation: slideUp 0.4s ease-out;
    animation-fill-mode: both;*/
}

.option-card:nth-child(1) {
  animation-delay: 0.1s;
}

.option-card:nth-child(2) {
  animation-delay: 0.2s;
}

.option-card:nth-child(3) {
  animation-delay: 0.3s;
}

.option-card:nth-child(4) {
  animation-delay: 0.4s;
}

.option-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  background-color: #f1f3f5;
}

.option-card.selected {
  background-color: #e6f3ff;
  border-color: #000080;
  box-shadow: 0 4px 12px rgba(0, 0, 128, 0.1);
}

.option-radio {
  margin-right: 15px;
  margin-top: 0px !important;
  margin-left: 0px !important;
}

.option-text {
  font-size: 16px;
  margin-left: 20px;
}

/* Form inputs animation */
.animated-input,
.animated-select {
  transition: all 0.3s ease;
  border: 1px solid #ced4da;
  border-radius: 8px;
  padding: 12px;
  animation: fadeIn 0.5s ease;
}

.animated-input:focus,
.animated-select:focus {
  border-color: #000080;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 128, 0.25);
}

/* Navigation buttons */
.navigation-buttons {
  margin-top: 30px;
}

.btn-prev,
.btn-next {
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.btn-next {
  background-color: #000080;
  border-color: #000080;
}

.btn-next:hover:not(:disabled) {
  background-color: #000066;
  transform: translateX(3px);
}

.btn-prev:hover:not(:disabled) {
  transform: translateX(-3px);
}

/* Results modal styling */
.results-modal .modal-content {
  border-radius: 16px;
  overflow: hidden;
}

.results-modal .modal-header {
  background-color: #000080;
  padding: 20px 30px;
}

.results-modal .modal-body {
  padding: 30px;
}

.results-content {
  animation: fadeIn 0.8s ease;
}

.results-score {
  animation: scaleIn 0.6s ease;
}

.score-progress {
  height: 20px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  animation: growWidth 1.5s ease-out;
}

.result-category {
  font-weight: bold;
  color: #000080;
  animation: pulseText 2s infinite;
}

.result-description {
  animation: fadeIn 0.8s ease;
  animation-delay: 0.3s;
  animation-fill-mode: both;
}

.next-steps {
  background-color: #f5f6fa;
  border-radius: 12px;
  border-left: 4px solid #000080;
  animation: slideUp 0.8s ease;
  animation-delay: 0.5s;
  animation-fill-mode: both;
}

.pulse-button {
  animation: pulse 2s infinite;
  background-color: #000080;
  border-color: #000080;
}

/* Loading animation */
.loading-animation {
  margin: 30px auto;
  animation: spin 1s infinite linear;
}

/* Slide animations for question transitions */
.slide-next-enter {
  opacity: 0;
  transform: translateX(20px);
}

.slide-next-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 300ms ease-in-out;
}

.slide-next-exit {
  opacity: 1;
  transform: translateX(0);
}

.slide-next-exit-active {
  opacity: 0;
  transform: translateX(-20px);
  transition: all 300ms ease-in-out;
}

.slide-prev-enter {
  opacity: 0;
  transform: translateX(-20px);
}

.slide-prev-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 300ms ease-in-out;
}

.slide-prev-exit {
  opacity: 1;
  transform: translateX(0);
}

.slide-prev-exit-active {
  opacity: 0;
  transform: translateX(20px);
  transition: all 300ms ease-in-out;
}

/* Keyframe animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes growWidth {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes pulseText {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 128, 0.6);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(0, 0, 128, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 128, 0);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Responsive styles */
@media (max-width: 992px) {
  .quiz-sidebar {
    width: 250px;
  }

  .quiz-content {
    margin-left: 250px;
  }

  .quiz-question-card {
    padding: 30px;
  }
}

@media (max-width: 768px) {

  .quiz-main-container {
    flex-direction: column;
  }

  .quiz-sidebar {
    width: 100%;
    height: auto;
    position: relative;
    margin-bottom: 20px;
    padding: 15px;
  }

  .section-progress {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .section-indicator {
    margin-right: 10px;
    width: 40px;
    height: 40px;
    justify-content: center;
  }

  .section-indicator:after {
    display: none;
  }

  .section-number {
    margin-right: 0;
  }

  .quiz-content {
    margin-left: 0;
    padding: 15px;
  }

  .quiz-question-card {
    padding: 20px;
  }

  .question-title {
    font-size: 1.25rem;
  }
}

@media (max-width: 576px) {
  .quiz-banner {
    height: 100px;
  }

  .quiz-main-container {
    margin-top: 126px;
    /* Adjusted for smaller banner */
  }

  .option-card {
    padding: 12px 15px;
  }

  .btn-prev,
  .btn-next {
    padding: 8px 16px;
    font-size: 14px;
  }

  .results-modal .modal-body {
    padding: 20px;
  }
}