/* Carousel Controls */
.carousel-control-prev,
.carousel-control-next {
  width: 10%;
  opacity: 0.8;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  z-index: 10;
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  opacity: 1;
  background: rgba(0, 0, 0, 0.5);
}

/* Carousel Item */
.carousel-item {
  position: relative;
  width: 100%;
}

.carousel-background {
  transition: transform 0.3s ease-in-out;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .container-fluid {
    padding: 1rem 1.5rem !important;
  }

  div[style*="padding: 1rem 2rem"] {
    padding: 1rem !important;
  }
}

@media (max-width: 576px) {
  h2[style*="fontSize"] {
    font-size: calc(1.2rem + 1vw) !important;
  }

  p[style*="fontSize"] {
    font-size: calc(0.9rem + 0.5vw) !important;
  }
}

@media (max-width: 320px) {
  .container-fluid {
    padding: 0.5rem 1rem !important;
  }
}

/* Fix for carousel control positioning */
.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

/* Optional: Add smooth transitions */
.carousel-item {
  transition: transform 0.6s ease-in-out;
}
