.book-section{
    //margin-top: 200px
    position: relative;

}

.coloured-cards{
    position: absolute;
    width: 100%;
    left: 0;
    top: -280%;
}