.scrolling-container {
  display: flex;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
}

.countryFlags {
  display: inline-block;
  animation: slide 20s linear infinite;
}

.countryFlags img {
  width: 100%;
  max-width: 200px;
  height: auto;
  border-radius: 8px;
  padding: 8px;
}

@keyframes slide {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.count-up-section {
  background-color: #0e0044;
  padding: clamp(2rem, 5vw, 4rem) 0;
  color: white;
  margin-bottom: 40px;
}

.count-up-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  gap: clamp(1rem, 3vw, 2rem);
}

.count-up-item {
  flex: 1;
  min-width: 280px;
  padding: clamp(1rem, 3vw, 2rem);
  position: relative;
}

.count-up-number {
  font-size: clamp(2rem, 5vw, 2.5rem);
  font-weight: bold;
  margin-bottom: clamp(0.5rem, 2vw, 1rem);
}

.count-up-text {
  font-size: clamp(1rem, 2vw, 1.125rem);
  font-weight: 400;
  line-height: 1.4;
}

@media (max-width: 768px) {
  .count-up-container {
    flex-direction: column;
  }

  .count-up-item {
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .count-up-item:last-child {
    border-bottom: none;
  }

  .scrolling-container {
    margin: 0 -10px;
  }
}

@media (min-width: 769px) {
  .count-up-item:not(:last-child)::after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 60%;
    width: 1px;
    background-color: rgba(255, 255, 255, 0.1);
  }
}

@media (max-width: 576px) {
  .countryFlags img {
    max-width: 150px;
    padding: 4px;
  }
}

.flags-scroll-container {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.flags-wrapper {
  display: flex;
  animation: scrollFlags 40s linear infinite;
}

.flag-item {
  flex: 0 0 auto;
  padding: 0 10px;
  width: 200px;
}

.flag-item img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: contain;
}

@keyframes scrollFlags {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@media (max-width: 768px) {
  .flag-item {
    width: 150px;
  }
}

@media (max-width: 576px) {
  .flag-item {
    width: 120px;
    padding: 0 5px;
  }
}
